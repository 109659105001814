"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "footer-content"
  }, [_c("div", {
    staticClass: "footer_links"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus?to=introduce"
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("关于我们")]), _vm._v("| "), _c("router-link", {
    attrs: {
      to: {
        path: "/aboutus.html?to=contact"
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("联系我们")]), _vm._v(" | "), _c("router-link", {
    attrs: {
      to: {
        path: "/goods"
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("交易大厅")]), _vm._v(" | | "), _c("router-link", {
    attrs: {
      to: {
        path: "/newslist",
        query: {
          isSearch: "false",
          searchVal: "",
          isDetail: "false"
        }
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("危化头条")]), _vm._v(" | "), _c("a", {
    attrs: {
      href: "https://sj.qq.com/myapp/detail.htm?apkName=com.danger",
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("APP下载")]), _vm._v(" | "), _c("router-link", {
    attrs: {
      to: {
        path: "/WMS"
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("WMS")]), _vm._v(" | "), _c("router-link", {
    attrs: {
      to: {
        path: "/tms"
      },
      underline: false,
      target: "_blank"
    }
  }, [_vm._v("TMS")]), _vm._v(" | ")], 1), _c("div", {
    staticClass: "noSelect"
  }, [_vm._v("Copyright@fjkuu.com Inc.保留所有权利。 闽ICP备17016051号 公共事务邮箱hwc@whbj.onaliyun.com 招聘联系邮箱hr@whbj.onaliyun.com")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;