"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "welcome_container",
    attrs: {
      id: "welcome"
    }
  }, [_c("div", {
    staticClass: "welcome_container_back"
  }, [_c("div", {
    staticClass: "welcome_line_back"
  }, [_c("div", {
    staticClass: "welcome_earth felx-row-center"
  }, [_c("img", {
    staticClass: "unrotate_earth",
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/welcome/welcome_earth.png"
    }
  }), _c("div", {
    staticClass: "welcome_floor"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/welcome/welcome_floor.png"
    }
  }), _c("div", {
    staticClass: "welcome_light"
  }, [_c("img", {
    staticClass: "light_img",
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/welcome/welcome_light.png"
    }
  }), _c("div", {
    staticClass: "line-container"
  }, [_c("div", {
    staticClass: "sparkLine"
  }, _vm._l(500, function (item) {
    return _c("div", {
      key: item,
      class: item > 70 && item < 80 ? "sparkParticle" : "sparkParticle2",
      style: {
        "-webkit-animation": "fade 5s " + item / 1000 + "s"
      }
    });
  }), 0)]), _c("div", {
    staticClass: "baloon_box"
  }, [_vm.showbalone ? _c("div", {
    staticClass: "ballon_item"
  }, [_c("div", {
    staticClass: "balloon text-white"
  }, [_vm._v(_vm._s(parseInt(_vm.baldata.vehicleSourceTotal / 10000 + 0.5)) + "万条")]), _c("div", {
    staticClass: "bal_title nowrap"
  }, [_vm._v("车源总数")])]) : _vm._e(), _vm.showbaltwo ? _c("div", {
    staticClass: "ballon_item"
  }, [_c("div", {
    staticClass: "balloon text-white"
  }, [_vm._v(_vm._s(parseInt(_vm.baldata.goodsSourceTotal / 10000 + 0.5)) + "万条")]), _c("div", {
    staticClass: "bal_title nowrap"
  }, [_vm._v("货源总数")])]) : _vm._e(), _vm.showbalthree ? _c("div", {
    staticClass: "ballon_item"
  }, [_c("div", {
    staticClass: "balloon"
  }, [_vm._v(_vm._s(_vm.baldata.goodsSourceHours) + "条")]), _c("div", {
    staticClass: "bal_title nowrap"
  }, [_vm._v("24小时更新数")])]) : _vm._e(), _vm.showbalfour ? _c("div", {
    staticClass: "ballon_item"
  }, [_c("div", {
    staticClass: "balloon"
  }, [_vm._v(_vm._s(parseInt(_vm.baldata.articleTotal / 10000 + 0.5)) + "万条")]), _c("div", {
    staticClass: "bal_title nowrap"
  }, [_vm._v("资讯总数")])]) : _vm._e(), _vm.showbalfive ? _c("div", {
    staticClass: "ballon_item"
  }, [_c("div", {
    staticClass: "balloon"
  }, [_vm._v(_vm._s(_vm.baldata.goodsSourceTotalMoney) + "亿元")]), _c("div", {
    staticClass: "bal_title nowrap"
  }, [_vm._v("运费估值")])]) : _vm._e()])]), _vm._m(0), _c("div", {
    staticClass: "welcome_btn"
  }, [_c("router-link", {
    staticClass: "mouse-pointer",
    attrs: {
      to: {
        name: "home"
      }
    }
  }, [_c("el-button", {
    directives: [{
      name: "track-event",
      rawName: "v-track-event",
      value: {
        category: "进入首页",
        action: "点击",
        opt_label: ""
      },
      expression: "{category:'进入首页', action:'点击', opt_label:''}"
    }],
    staticClass: "tohome_btn text-white mouse-pointer",
    attrs: {
      round: ""
    }
  }, [_vm._v("进入首页")])], 1)], 1)])]), _c("div", {
    staticClass: "record_txt text-white no-select"
  }, [_vm._v("© fjkuu,Inc.All rights reserved.保留所有权利。 "), _c("a", {
    staticClass: "text-white",
    attrs: {
      href: "https://beian.miit.gov.cn",
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$root.recordTxt))]), _vm._v(" 公共事务邮箱hwc@whbj.onaliyun.com 招聘联系邮箱hr@whbj.onaliyun.com ")])]), _c("shader-wave", {
    attrs: {
      speed: _vm.wavespeed,
      width: _vm.wavewidth,
      depth: _vm.wavedepth
    }
  })], 1), _c("mouse-line", {
    attrs: {
      nb: _vm.mouselinenb
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "welcome_text"
  }, [_c("span", {
    staticClass: "welcome_bigTitle"
  }, [_vm._v("欢迎来到危化镖局")]), _c("span", {
    staticClass: "welcome_secTitle"
  }, [_vm._v("VESCORT • 化工供应链一站式综合服务平台")])]);
}];
render._withStripped = true;