"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer-nav",
    staticStyle: {
      position: "relative"
    }
  }, [_c("el-footer", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "footer_main flex-row-sb align-item-start"
  }, [_c("div", {
    staticClass: "flex-row-start"
  }, [_c("div", {
    staticClass: "footer_center flex-row-start"
  }, [_c("div", {
    staticClass: "f_links f_aboutus text-left"
  }, [_c("dl", [_c("dt", [_vm._v("首页")]), _c("dd", {
    staticClass: "margin-top-15"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/home"
      }
    }
  }, [_vm._v("化工物流生态体系")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/home?to=cont-03"
      }
    }
  }, [_vm._v("车货匹配")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/home?to=cont-05"
      }
    }
  }, [_vm._v("合作网点")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/home?to=cont-06"
      }
    }
  }, [_vm._v("安全管理")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/home?to=cont-04"
      }
    }
  }, [_vm._v("增值服务")])], 1)])]), _c("div", {
    staticClass: "f_links f_products text-left"
  }, [_c("dl", [_c("dt", [_vm._v("解决方案")]), _c("dd", {
    staticClass: "margin-top-15"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/logistics"
      }
    }
  }, [_vm._v("物流解决方案")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/bussiness"
      }
    }
  }, [_vm._v("商务解决方案")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/finance"
      }
    }
  }, [_vm._v("财务统筹")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/insurance"
      }
    }
  }, [_vm._v("镖局保险")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/software"
      }
    }
  }, [_vm._v("软件解决方案")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/TMS"
      }
    }
  }, [_vm._v("TMS-运输管理系统")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/WMS"
      }
    }
  }, [_vm._v("WMS-仓储管理系统")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/wisdom5G"
      }
    }
  }, [_vm._v("5G智慧管控平台")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/chain"
      }
    }
  }, [_vm._v("数字供应链产品")])], 1), _c("dd", [_c("router-link", {
    staticStyle: {
      color: "#67717a"
    },
    attrs: {
      to: {
        path: "/logisticsPark"
      }
    }
  }, [_vm._v("鑫展旺物流园区")])], 1)])]), _c("div", {
    staticClass: "f_links f_products text-left"
  }, [_c("dl", [_c("dt", [_vm._v("物流联盟")]), _c("dd", {
    staticClass: "margin-top-15"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm"
      }
    }
  }, [_vm._v("合作愿景")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-02"
      }
    }
  }, [_vm._v("加盟条件")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-03-01"
      }
    }
  }, [_vm._v("产品赋能")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-03-02"
      }
    }
  }, [_vm._v("商业赋能")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-04"
      }
    }
  }, [_vm._v("我们的优势")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-05"
      }
    }
  }, [_vm._v("赚钱的大联盟")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-06"
      }
    }
  }, [_vm._v("省钱的大联盟")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-07"
      }
    }
  }, [_vm._v("靠得住的伙伴")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/wllm?to=cont-08"
      }
    }
  }, [_vm._v("加入千城联盟")])], 1)])]), _c("div", {
    staticClass: "f_links f_products text-left"
  }, [_c("dl", [_c("dt", [_vm._v("化工信息")]), _c("dd", {
    staticClass: "margin-top-15"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/goods"
      }
    }
  }, [_vm._v("交易大厅")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/specialVehicle"
      }
    }
  }, [_vm._v("镖局专车")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/chemicalMall"
      }
    }
  }, [_vm._v("化工商城")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/waybill"
      }
    }
  }, [_vm._v("电子运单")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/msdsList"
      }
    }
  }, [_vm._v("MSDS")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/newslist",
        query: {
          isSearch: false,
          searchVal: "",
          isDetail: false
        },
        params: {
          isSearch: false
        }
      }
    }
  }, [_vm._v("危化头条")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/cooperationMall/zpList"
      }
    }
  }, [_vm._v("危化商铺")])], 1)])]), _c("div", {
    staticClass: "f_links f_products text-left"
  }, [_c("dl", [_c("dt", [_vm._v("关于我们")]), _c("dd", {
    staticClass: "margin-top-15"
  }, [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus"
      }
    }
  }, [_vm._v("公司简介")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus?to=culture"
      }
    }
  }, [_vm._v("公司理念")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus?to=honor"
      }
    }
  }, [_vm._v("公司荣誉")])], 1), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus?to=company"
      }
    }
  }, [_vm._v("合作企业")])], 1), _c("dd", [_c("a", {
    attrs: {
      href: "https://www.xmrc.com.cn/net/info/showcojob.aspx?CompanyID=934738"
    }
  }, [_vm._v("招贤纳士")])]), _c("dd", [_c("router-link", {
    attrs: {
      to: {
        path: "/aboutus?to=contact"
      }
    }
  }, [_vm._v("联系我们")])], 1)])])])]), _c("div", {
    staticClass: "flex-row-end"
  }, [_c("div", {
    staticClass: "footer_right"
  }, [_c("div", {
    staticClass: "flex-row-start"
  }, [_c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "light",
      transition: "none .1s",
      "popper-class": "slide-nav-tool"
    }
  }, [_c("div", {
    staticClass: "tooltip-box",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticClass: "kefu_con"
  }, [_c("img", {
    attrs: {
      src: require("../../src/assets/static/img/sidenav/06.png"),
      alt: ""
    }
  }), _c("a", {
    attrs: {
      href: "javascript:void(0);"
    }
  }, [_vm._v("400-886-3156")])]), _c("div", {
    staticClass: "kefu_con"
  }, [_c("img", {
    attrs: {
      src: require("../../src/assets/static/img/sidenav/05.png"),
      alt: ""
    }
  }), _c("a", {
    staticClass: "mouse-pointer",
    attrs: {
      target: "_blank",
      href: "http://wpa.qq.com/msgrd?v=3&uin=1436898740&site=qq&menu=yes"
    }
  }, [_vm._v("1436898740")])])]), _c("img", {
    staticClass: "width-40 margin-right-20",
    attrs: {
      src: require("../assets/static/img/logo/01.png")
    }
  })]), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "light",
      transition: "none .1s",
      "popper-class": "footer-tool"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("img", {
    staticClass: "width-120",
    attrs: {
      src: "https://sit.fjkuu.com/img/dingyuehao.jpg"
    }
  })]), _c("img", {
    staticClass: "width-40 margin-right-20",
    attrs: {
      src: require("../assets/static/img/logo/02.png")
    }
  })]), _c("a", {
    attrs: {
      id: "footer_qq",
      target: "_blank",
      href: "http://wpa.qq.com/msgrd?v=3&uin=1436898740&site=qq&menu=yes"
    }
  }), _c("el-tooltip", {
    attrs: {
      placement: "top",
      effect: "light",
      transition: "none .1s",
      "popper-class": "footer-tool"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("img", {
    staticClass: "width-120",
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/weibo_code.png"
    }
  })]), _c("img", {
    staticClass: "width-40 margin-right-20",
    attrs: {
      src: require("../assets/static/img/logo/04.png")
    }
  })])], 1), _c("div", {
    staticClass: "margin-top-30 flex-row-start"
  }, [_c("div", {
    staticClass: "margin-right-20"
  }, [_c("img", {
    staticClass: "width-90",
    attrs: {
      src: require("../assets/static/img/logo/06.jpg")
    }
  }), _c("div", {
    staticClass: "font-12 color-white margin-top-10 text-center"
  }, [_vm._v("公众号二维码")])]), _c("div", {
    staticClass: "margin-right-20"
  }, [_c("img", {
    staticClass: "width-90",
    attrs: {
      src: require("../assets/static/img/logo/05.jpg")
    }
  }), _c("div", {
    staticClass: "font-12 color-white margin-top-10 text-center"
  }, [_vm._v("APP下载二维码")])]), _c("div", [_c("img", {
    staticClass: "width-90",
    attrs: {
      src: require("../assets/static/img/logo/07.jpg")
    }
  }), _c("div", {
    staticClass: "font-12 color-white margin-top-10 text-center"
  }, [_vm._v("名片小程序")])])])])])]), _c("div", {
    staticClass: "no-select color-AAADB0",
    staticStyle: {
      height: "75px",
      "line-height": "75px",
      "font-size": "14px",
      "border-top": "1px solid #3E444D"
    }
  }, [_vm._v("© fjkuu,Inc.All rights reserved.保留所有权利。 "), _c("a", {
    staticClass: "color-AAADB0",
    attrs: {
      href: "https://beian.miit.gov.cn",
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$root.recordTxt))]), _vm._v(" 公共事务邮箱hwc@whbj.onaliyun.com 招聘联系邮箱hr@whbj.onaliyun.com ")])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;